import React, { useMemo } from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { ExternalLink } from '../ExternalLink';

type CurrentRoleCardProps = {
  details: {
    companyUrl: string | null;
    title: string | null;
    companyLogo: {
      publicURL: string | null;
      extension: string;
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      } | null;
    } | null;
  };
};

export const CurrentRoleCard = (props: CurrentRoleCardProps) => {
  const { companyLogo, companyUrl, title } = props.details;

  const CompanyLogo = useMemo(() => {
    if (companyLogo?.extension === 'svg') {
      return !!companyLogo?.publicURL ? (
        <img src={companyLogo?.publicURL} alt={companyUrl ?? ''} />
      ) : undefined;
    }

    return !!companyLogo?.childImageSharp ? (
      <GatsbyImage
        image={companyLogo.childImageSharp.gatsbyImageData}
        alt={companyUrl ?? ''}
      />
    ) : undefined;
  }, [companyLogo]);

  return (
    <div className="relative mb-3 py-4 px-3 w-[300px] bg-gray-100 dark:bg-gray-900 rounded-md shadow-default">
      <div className="max-w-[140px] max-h-9 mb-1">{CompanyLogo}</div>
      <span className="block mt-8 mb-1 text-gray-900 dark:text-gray-50">
        {title}
      </span>
      {!!companyUrl ? (
        <ExternalLink href={companyUrl} className="link text-sm" data-show-hint>
          {companyUrl}
        </ExternalLink>
      ) : undefined}
    </div>
  );
};
